import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

import petnotchLogo from "../assets/logo_variant.png"; // Replace with the actual path to your logo

function DeleteAccount() {
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [step, setStep] = useState(1);
  const [deletionRequestId, setDeletionRequestId] = useState('');
  const [otp, setOtp] = useState('');

  const handleChange = (e) => {
    if (e.target.name === 'email') {
      setEmail(e.target.value);
    } else if (e.target.name === 'comment') {
      setComment(e.target.value);
    } else if (e.target.name === 'otp') {
      setOtp(e.target.value);
    }
  };

  const handleDeleteAccountRequest = async (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'Delete Account',
      action: 'Delete Account Request Initiated',
      label: email,
      nonInteraction: false
    });

    try {
      const response = await axios.post(
        '/api/user/delete-account-request',
        {
          email: email,
          reason: comment, // Include the comment in the request
        }
      );

      if (response.status === 201) {
        setStep(2);
        setDeletionRequestId(response.data.id); // Store the deletion request ID
        toast.success('Deletion request created. Check your email for OTP.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
        });

        ReactGA.event({
          category: 'Delete Account',
          action: 'Delete Account OTP Sent',
          label: email,
          nonInteraction: false
        });
      } else {
        toast.error('Something went wrong. Please try again later!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
        });
        ReactGA.event({
          category: 'Delete Account',
          action: 'Delete Account OTP Send Failed',
          label: email,
          nonInteraction: false
        });
      }
    } catch (error) {
      toast.error('Something went wrong. Please ensure your email id is correct.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
      ReactGA.event({
        category: 'Delete Account',
        action: 'Delete Account OTP Send Errored',
        label: email,
        nonInteraction: false
      });
    }
  };

  const handleVerifyOTP = async (event) => {
    event.preventDefault();
    
    try {
      const response = await axios.post(
        `/api/user/delete-account-request-verify`,
        {
          otp: otp,
          id: deletionRequestId,
        }
      );
      ReactGA.event({
        category: 'Delete Account',
        action: 'Delete Account OTP Verification Initiated',
        label: email,
        nonInteraction: false
      });

      if (response.status === 200) {
        toast.success('Account deleted successfully.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
        });
        ReactGA.event({
          category: 'Delete Account',
          action: 'Delete Account Request Success',
          label: email,
          nonInteraction: false
        });
        setStep(3); // Account deleted
      } else {
        toast.error('Invalid OTP. Please try again.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
        });
        ReactGA.event({
          category: 'Delete Account',
          action: 'Delete Account Request OTP Failed',
          label: email,
          nonInteraction: false
        });
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again later or contact us at support@petnotch.com.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
      ReactGA.event({
        category: 'Delete Account',
        action: 'Delete Account Request OTP Verification Errored',
        label: email,
        nonInteraction: false
      });
    }
  };

  const location = useLocation();
  ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Delete Account" });

  return (
    <>
      <div className="bg-primary h-96 flex items-center" id="delete-account">
        <h2 className="max-w-lg mb-20 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-5xl md:mx-auto">
          Delete Your Account
        </h2>
      </div>
      <div className="relative px-4 py-16 mx-auto overflow-hidden sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 absolute bottom-60">
        <div className="flex flex-col items-center justify-between xl:flex-row bg-white rounded shadow-2xl p-7 sm:p-10">

          <img
          src={petnotchLogo}
          alt="PetNotch Logo"
          className="mb-4 ml-8"
          style={{ maxWidth: '400px' }} // Adjust the size as needed
        />
          <div className="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-5/12">
            {step === 1 && (
              <>
                <h2 className="max-w-lg mb-10 text-3xl font-bold text-secondary sm:text-4xl sm:leading-none">
                  Step 1: Request Account Deletion
                </h2>
                <form onSubmit={handleDeleteAccountRequest}>
                  <div className="mb-1 sm:mb-2">
                    <input
                      placeholder="Enter your email"
                      required
                      type="text"
                      className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-light rounded shadow-sm appearance-none"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-1 sm:mb-2">
                    <textarea
                      placeholder="Optional comment/reason for closing your account"
                      rows="4"
                      className="flex-grow w-full px-4 py-2 mb-2 transition duration-200 bg-light rounded shadow-sm appearance-none"
                      id="comment"
                      name="comment"
                      value={comment}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="mt-4 mb-2 sm:mb-4">
                    <button
                      type="submit"
                      className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide transition duration-200 rounded shadow-md bg-secondary text-white focus:shadow-outline focus:outline-none"
                    >
                      Request Account Deletion
                    </button>
                  </div>
                </form>
              </>
            )}
            {step === 2 && (
              <>
                <h2 className="max-w-lg mb-10 text-3xl font-bold text-secondary sm:text-4xl sm:leading-none">
                  Step 2: Verify OTP
                </h2>
                <form onSubmit={handleVerifyOTP}>
                  <div className="mb-1 sm:mb-2">
                    <input
                      placeholder="Enter OTP received in your email"
                      required
                      type="number"
                      className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-light rounded shadow-sm appearance-none"
                      id="otp"
                      name="otp"
                      value={otp}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mt-4 mb-2 sm:mb-4">
                    <button
                      type="submit"
                      className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide transition duration-200 rounded shadow-md bg-secondary text-white focus:shadow-outline focus:outline-none"
                    >
                      Verify OTP and Delete Account
                    </button>
                  </div>
                </form>
              </>
            )}
            {step === 3 && (
              <p>Your account has been deleted successfully.</p>
            )}
            <ToastContainer />
          </div>
          <div />
        </div>
      </div>
    </>
  );
}

export default DeleteAccount;
