import { useEffect} from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

import '../base.css';
import petnotchLogo from "../assets/logo_variant.png";

const TermsOfUse = () => {

  useEffect(() => {
    document.title = "Terms of Use | PetNotch";  
  }, []);

  const location = useLocation();
  ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Terms of Use" });

  return (
    <div
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl"
      id="termsOfUse"
    >
      <img
          src={petnotchLogo}
          alt="PetNotch Logo"
          className="mb-8 ml-8"
          style={{ maxWidth: '100px' }} // Adjust the size as needed
      />
      <div className="">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-black sm:text-6xl md:mx-auto">
          Terms Of Use
        </h2>
        <p className="heading blue">User Verification</p>
<p className='sub-heading'>Verification<br></br></p>
PetNotch does not personally verify carers and pets on the platform. We do not guarantee or take responsibility for the behavior, actions, or statements of users on the platform, whether online or during in-person meetings. PetNotch is not responsible for the accuracy or completeness of user profiles.

<p className="heading blue">User Age Limitation</p>
<p className='sub-heading'>Minimum Age<br></br></p>
Users of PetNotch must be at least 13 years old to create an account and use our services. By registering on PetNotch, users confirm that they meet this age requirement. PetNotch may terminate the accounts of users who are found to be under the age of 13.


<p className="heading blue">Content and Behavior</p>
<p className='sub-heading'>Pet Friendly<br></br></p>
Users are prohibited from posting, sharing, or promoting any content that is not pet friendly. PetNotch strongly advises against posting content that display any sort of animal cruelty, abuse, voilence or ill treatment. All users are advised to report to any such incidents to local authorities in their region.
<p className='sub-heading'>Inappropriate Content<br></br></p>
Users are prohibited from posting, sharing, or promoting any content that is voilent, explicit, offensive, discriminatory, or illegal. PetNotch reserves the right to remove such content and suspend or terminate accounts of users found in violation.
<p className='sub-heading'>Harassment and Bullying<br></br></p>
Harassment, bullying, or any form of abusive behavior towards other users will not be tolerated on PetNotch. Such actions may result in immediate account suspension.
<p className='sub-heading'>Respect for Others<br></br></p>
Users are expected to treat fellow users with respect and courtesy. Discriminatory, offensive, or disrespectful comments or actions towards others are strictly prohibited.
<p className='sub-heading'>Content Removal<br></br></p>
PetNotch reserves the right to remove any content that violates its terms of service, even if it does not violate the law.

<p className="heading blue">Content Elevation</p>
Users can elevate their content by using the elevate option available on the app during the creation of an event or where applicable. There are two types of elevations options available. User can choose to either one of them or both. Users are required to pay for the elevation services as per the charges mentioned in the app.
<p className='sub-heading'>Spotlight<br></br></p>
Spotlight events are elevated to the top of the feed for a specified duration. Users can choose the duration of the spotlight event during the creation process. Spotlighted events also show up in the home tab of the app to users we think might be interested in the event based on various factors including but not limited to location, pet preferences, etc.
<p className='sub-heading'>PetLerts<br></br></p>
Petlerts are elevated to send notifications to nearby users who have opted in to receive petlerts. Petlerts are used for events that require immediate attention, such as lost pets, temporary care, breeding, playdates, etc. While users can choose to send petlerts for all type of events but we strongly advise to use it only for events that require immediate attention. Number of petlerts requested may vary from actual number of petlerts sent based on various factors including but not limited to location, pet preferences, previous interactions, etc. However, the user will be charged for the number of petlerts sent or 0.5 USD or equivalent, whichever is higher.

<p className="heading blue">Communication</p>
<p className='sub-heading'>Email Communications<br></br></p>
Users should expect to receive emails related to account maintenance at the email address provided during registration.
<p className='sub-heading'>Marketing Communications<br></br></p>
We send marketing and product related email communications to out users unless they have opted out of it.
<p className='sub-heading'>Opt Out<br></br></p>
Users may opt out of marketing and product related email communications at any time by clicking the unsubscribe link in the email.
<p className="heading blue">Notifications</p>
<p className='sub-heading'>Communication Preferences<br></br></p>
Users can manage their notification preferences within the app settings, allowing them to control the types and frequency of notifications they receive.
<p className='sub-heading'>Petlerts (Notifications)<br></br></p>
All PetNotch users agree to receive mandatory petlerts (notifications) when nearby carers require support for events such as but not restricted to lost pets, temporary care, breeding, playdates, etc. These are mandatory notifications and cannot be turned off. Users signing up for PetNotch agree to receive these notifications.

<p className="heading blue">Payment</p>
<p className='sub-heading'>Currency Of Charge<br></br></p>
We strive to charge your card in your local currency to avoid conversion fees. This applies to Australian (AUD) and Indian (INR) cards. For cards issued outside of Australia and India, the charge will be processed in US Dollars (USD).
<p className='sub-heading'>Spotlight<br></br></p>
For elevated spotlight events, the payment for the minimum duration will be charged upon event creation and is non-refundable. Any additional duration charges will apply after the event has been live for the corresponding minutes.
<p className='sub-heading'>PetLerts<br></br></p>
For petlerts, the full payment will be charged in advance.
<p className='sub-heading'>Payment Processing<br></br></p>
PetNotch uses a third party service called Stripe to process all payments. All payment methods as stored as per terms available at <a href="https://stripe.com/en-au/legal/payment-terms/">stripe's</a> website. PetNotch is not responsible for any disputes between users and third party payment processors.
<p className='sub-heading'>Refund Process<br></br></p>
To request a refund, users must use the support option on the app. Refund requests will be acknowledged or processed within 14 working days
<p className='sub-heading'>Refund Timelines<br></br></p>
After processing, refunds may take up to 14 working days to reflect in the user's account, depending on their card issuer, geographical location, and payment scheme.
<p className='sub-heading'>Payment Authorization<br></br></p>
Users grant permission for PetNotch to initiate payments, whether one-time or recurring, on their behalf, as determined by the event and user preferences.
<p className='sub-heading'>Minimum Charge<br></br></p>
To ensure a smooth transaction, a minimum fee of 0.50 AUD (or equivalent) may be applied if a charge is due. Please note that the exchange rate used for conversion, if applicable, may be up to one month old.

<p className="heading blue">Coupons</p>
PetNotch may offer coupons to users for promotional purposes. Coupons are subject to specific terms and conditions, including expiration dates and usage limits. Users are responsible for reviewing these conditions before applying a coupon.
<p className="sub-heading">Coupon Usage<br /></p>
 <p>
    Users can apply valid coupons to some purchases made on PetNotch. However, coupons are subject to specific terms and conditions, including expiration dates and usage limits.
  </p>
  <p className="sub-heading">Past Purchases<br /></p>
  <p>
      Coupons cannot be applied to past purchases or used in combination with other offers unless explicitly stated in the coupon details.
  </p>
  <p className="sub-heading">Non Transferable<br /></p>
    <p>
        Coupons are not transferable and cannot be redeemed for cash.
    </p>
  <p className="sub-heading">Revocation and Cancellation<br /></p>
    <p>
        PetNotch reserves the right to revoke or cancel coupons at our discretion, in accordance with these Terms and Conditions.
    </p>
  <p className="sub-heading">Refunds and Replacements<br /></p>
    <p>
        If a coupon is revoked or canceled, the user will not be entitled to a refund or replacement.
    </p>
  <p className="sub-heading">Lost or Stolen Coupons<br /></p>
    <p>
        PetNotch is not responsible for lost or stolen coupons.
    </p>
  <p className="sub-heading">Coupon Limitations<br /></p>
    <p>
        Coupons are subject to expiration dates and usage limits. Please review the coupon details for more information.
    </p>
  <p className="sub-heading">Credit Coupon Types<br /></p>
    <p>
      Credit coupons may be used multiple times until fully utilized subject to the terms and conditions of the coupon. However, PetNotch does not guarantee the availability of the coupon for future purchases.
    </p>
  <p className="sub-heading">Discount Coupon Types<br /></p>
    <p>
      Discount coupons may be used only once and are subject to the terms and conditions of the coupon. Once used, the coupon will be considered redeemed and cannot be reused.
    </p>
  <p className="sub-heading">Payment Method<br /></p>
    <p>
      Users will still need to select a payment method regardless of the coupon value. If the coupon value is equal to or greater than the total purchase amount, the payment method will not be charged.
    </p>
  <p className="sub-heading">One Coupon Per Purchase<br /></p>
    <p>
      More than one coupon cannot be applied to a single purchase.
    </p>
  <p className="sub-heading">Coupon Expiry<br /></p>
    <p>
      Coupons are subject to expiration dates. Expired coupons cannot be used for purchases.
    </p>
  <p className="sub-heading">Limited Services<br /></p>
    <p>
      Coupons may be limited to specific services or products. Please review the coupon details for more information. PetNotch reserves the right to revoke or cancel coupons if they are used for services or products not covered by the coupon. PetNotch may also update the terms and conditions of the coupon at any time without prior notice and without any guarantee to notify the user.
    </p>

<p className="heading blue">Account Security</p>
<p className='sub-heading'>Account Responsibility<br></br></p>
Users are responsible for maintaining the security of their accounts. This includes keeping passwords confidential and not sharing their account access with others. PetNotch is not responsible for any losses or damages resulting from unauthorized access to or use of user accounts.
<p className='sub-heading'>Unauthorized Access<br></br></p>
Users must report any unauthorized access or use of their account to PetNotch immediately.

<p className="heading blue">Intellectual Property</p>
<p className='sub-heading'>Copyright and Ownership<br></br></p>
Users retain the copyright and ownership of the content they post on PetNotch. However, by posting content on the platform, users grant PetNotch a non-exclusive license to use, display, and distribute that content for the purpose of operating, marketing and promoting the platform.

<p className="heading blue">Legal Compliance</p>
<p className='sub-heading'>Compliance with Laws<br></br></p>
Users must comply with all applicable laws and regulations when using PetNotch, including but not limited to privacy laws and intellectual property rights. Users are responsible for complying with all applicable laws and regulations, including but not limited to privacy laws and intellectual property rights.

<p className="heading blue">Liability</p>
<p className='sub-heading'>Indemnification<br></br></p>
Users agree to indemnify and hold PetNotch harmless from any claims, damages, or losses arising from their use of the platform or any violation of these terms and conditions.
<p className='sub-heading'>Limitation of Liability<br></br></p>
PetNotch is not liable for any indirect, incidental, or consequential damages resulting from the use of our services.

<p className="heading blue">Termination</p>
<p className='sub-heading'>Account Termination<br></br></p>
PetNotch reserves the right to terminate or suspend user accounts at our discretion, especially in cases of repeated violations of our terms and conditions.

<p className="heading blue">Data Handling</p>
<p className='sub-heading'>Data Security<br></br></p>
PetNotch takes reasonable measures to secure user data. However, users are responsible for their data, including the content they post. PetNotch is not liable for any data breaches or loss of user data.

<p className="heading blue">Modifications and Governing Law</p>
<p className='sub-heading'>Term Updates<br></br></p>
PetNotch may modify these terms of service at any time. Users will be notified of changes, and continued use of our services indicates acceptance of the updated terms.
<p className='sub-heading'>Governing Law<br></br></p>
These terms of service are governed by the laws of Australia and disputes will be resolved through binding arbitration in NSW, Australia.

<p className="heading blue">Blogs</p>
<p className='sub-heading'>Blog Content<br></br></p>
PetNotch blog content is for informational purposes only and should not be considered professional advice. Users should consult a professional for specific advice related to their situation.
<p className='sub-heading'>Blog Comments<br></br></p>
PetNotch reserves the right to remove or edit comments on blog posts that violate our terms of service or are otherwise inappropriate.
<p className="sub-heading">Blog Authors</p>
PetNotch blog authors could be anyone including but not limited to petnotch community members, petnotch team members, petnotch partners, domain experts etc. The views expressed in the blog are of the author and not of petnotch. PetNotch is not responsible for the accuracy or completeness of the blog content.
<br></br>
<br></br>
<br></br>
Thank you for choosing PetNotch!
      </div>
    </div>
  );
};

export default TermsOfUse;
