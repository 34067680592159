import React, { useState } from 'react';
import axios from 'axios';
import {
  Facebook,
  Instagram,
  EmailOutlined,
  LinkedIn,
} from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [agree, setAgree] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === 'name') {
      setName(e.target.value);
    } else if (e.target.name === 'message') {
      setMessage(e.target.value);
    }
    else if (e.target.name === 'email') {
      setEmail(e.target.value);
    }
  };

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
    // Add class to the button if agree and remove if not agree
    if (agree) {
      console.log('agree is false');
      document.getElementById('submit').classList.remove('hover:bg-primary');
      document.getElementById('submit').classList.remove('cusor-pointer');
      document.getElementById('submit').classList.add('cursor-not-allowed');
    } else {
      console.log('agree is true');
      // Add classes bg-secondary text-white hover:bg-primary 
      document.getElementById('submit').classList.remove('cursor-not-allowed');
      document.getElementById('submit').classList.add('hover:bg-primary');
      document.getElementById('submit').classList.add('cursor-pointer');
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.target.reset();
    // Capture response
    await axios.post('/api/send-message', {
      "email": email,
      "name": name,
      "message": message,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.status === 201) {
        // Show toast
        console.log(response.data);
        toast.success('Thanks for writing to us. We will get back to you soon!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
        });
      } else {
        console.log(response.data);
        toast.error('Something went wrong. Please try again later!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
        });
      }
    }).catch((error) => {
      toast.error('Something went wrong. Please try again later!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    }).finally(() => {
      setName('');
      setEmail('');
      setMessage('');
    });
    // Set name and email fields to empty
  };

  return (
      <>
        <div className="bg-primary h-96 flex items-center" id="contact">
          <h2 className="max-w-xlg mb-20 font-sans font-bold leading-none tracking-tight text-white text-4xl lg:text-5xl mx-auto text-center">
            Have any questions?
          </h2>
        </div>
        <div className="relative px-4 py-16 mx-auto overflow-hidden sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 absolute bottom-60">
          <div className="flex flex-col items-center justify-between xl:flex-row bg-white rounded shadow-2xl p-7 sm:p-10">
            <div className="w-full max-w-xl mb-12 xl:mb-0 xl:pr-8 xl:w-4/6">
              <div className="flex flex-col max-w-md sm:flex-row items-center justify-between mb-10">
                  <div className="flex items-center justify-center p-3 rounded-full bg-primary">
                    <EmailOutlined
                      style={{ color: "white", fontSize: 24}}
                    />
                  </div>
                  <div>
                    <h2 className="text-left">
                      Write to us at <u><a href = "mailto: contact@petnotch.com" className="text-secondary">contact@petnotch.com</a></u>
                    </h2>
                  </div>
                </div>
              <div>
                <h2 className="max-w-lg mb-5 text-2xl text-secondary sm:text-2xl sm:leading-none">
                  Follow us on
                </h2>
              </div>
              <div className="flex items-center mt-4 space-x-8 sm:mt-0">
                  <a
                      href="https://instagram.com/petnotch"
                      className="transition-colors duration-300 text-primary hover:text-white hover:bg-primary border-2 border-primary p-2 rounded-full"
                    >
                    <Instagram />
                  </a>
                  <a
                    href="https://facebook.com/petnotch"
                    className="transition-colors duration-300 text-primary hover:text-white hover:bg-primary border-2 border-primary p-2 rounded-full"
                  >
                    <Facebook />
                  </a>
                  <a
                    href="https://linkedin.com/company/petnotch"
                    className="transition-colors duration-300 text-primary hover:text-white hover:bg-primary border-2 border-primary p-2 rounded-full"
                  >
                    <LinkedIn />
                  </a>
              </div>
            </div>
            <div className="w-full max-w-l ml-10 mb-10 xl:mb-0 xl:pr-14 xl:w-1/5">
              <h3 className="max-w-lg mb-auto text-xl text-primary sm:text-xl sm:leading-none text-center font-bold">
                  OR
              </h3>
            </div>
            <div className="w-full max-w-xl xl:px-8 xl:w-4/6">
              <form onSubmit={handleSubmit}>
                <div className="mb-1 sm:mb-2">
                  <input
                    placeholder="Full name"
                    required
                    type="text"
                    className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-light rounded shadow-sm appearance-none"
                    id="name"
                    name={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="mb-1 sm:mb-2">
                  <input
                    placeholder="E-mail"
                    required
                    type="text"
                    className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-light rounded shadow-sm appearance-none"
                    id="email"
                    name={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-1 sm:mb-2">
                  <textarea
                    placeholder="Message"
                    required
                    type="textarea"
                    className="flex-grow w-full px-4 mb-2 transition duration-200 bg-light rounded shadow-sm appearance-none"
                    id="message"
                    name={message}
                    rows={3} 
                    cols={40}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="mb-1 sm:mb-2">
                <input type="checkbox" id="agree" onChange={checkboxHandler} />
                <label htmlFor="agree"> I confirm that i have read and agree to PetNotch's <b><a href='/legal/privacy-policy' target="_blank" className="link">privacy policy</a></b></label>
                </div>
                {/*
                <div className="mb-1 sm:mb-2">
                  <input
                    placeholder="Message"
                    required
                    type="text"
                    className="flex-grow w-full h-36 px-4 mb-2 transition duration-200 bg-light rounded shadow-sm appearance-none"
                    id="message"
                    name="message"
                  />
                </div>
                */}
                <div className="mt-4 mb-2 sm:mb-4">
                  <button
                    type="submit"
                    disabled={!agree}
                    id="submit"
                    className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide transition duration-200 rounded shadow-md bg-secondary text-white focus:shadow-outline focus:outline-none cursor-not-allowed"
                    /*onClick={btnHandler}/*/
                  >
                    Let's Get Going!
                  </button>
                  <ToastContainer />
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
};

export default Contact;
