import { useEffect} from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";


import '../base.css';
import petnotchLogo from "../assets/logo_variant.png";

const PrivacyPolicy = () => {

    useEffect(() => {
        document.title = "Privacy Policy | PetNotch";  
        }, []);

    const location = useLocation();
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Privacy Policy" });
    
  return (
    <div
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl"
      id="privacyPolicy"
    >
      <img
          src={petnotchLogo}
          alt="PetNotch Logo"
          className="mb-8 ml-8"
          style={{ maxWidth: '100px' }} // Adjust the size as needed
      />
      <div className="">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-black sm:text-6xl md:mx-auto">
          Privacy Policy
        </h2>
        <p className="heading blue">Introduction</p>
        Welcome to PetNotch ("we," "our," or "us"). At PetNotch, we take your privacy seriously. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our website and mobile application ("Services"). By using our Services, you consent to the practices described in this policy.
<p className="heading blue">Information We Collect</p>
We may collect the following types of information.
<p className='sub-heading'>Personal Information<br></br></p>
When you create an account or use our Services, we may collect personal information such as your name, email address, date of birth, phone number and profile picture. Users under the age of 13 are not permitted to use our Services.
<p className='sub-heading'>Content<br></br></p>
We collect and store content that you post, send, or receive on our platform, including photos, videos, and messages.
<p className='sub-heading'>Usage Data<br></br></p>
We collect data about the following aspects of your usage of our Services: the features you use, the content you view, the time, frequency, and duration of your activities, and the interactions you have with other users.
<p className='sub-heading'>Device Information<br></br></p>
We collect information about the device you use to access our Services, including the type of device, operating system, device settings, and unique device identifiers.
<p className='sub-heading'>Location Information<br></br></p>
With your consent, we may collect information about your precise or approximate location through GPS, Wi-Fi, or mobile network data.
<p className='sub-heading'>Files & Storage<br></br></p>
With your consent, we may read the metadata of all files stored in your device and also read content of the files including images, videos, documents or any other files you choose to upload to the platform.

<p className="heading blue">How We Use Your Information</p>
We use your information for the following purposes
<p className='sub-heading'>Providing and Improving Services<br></br></p>
We use your information to deliver and enhance our Services, personalize your experience, and develop new features.
<p className='sub-heading'>Communication<br></br></p>
We may use your contact information to communicate with you about your account, updates, and promotional offers.
<p className='sub-heading'>Analytics and Research<br></br></p>
We analyze user data to understand trends and user preferences, helping us improve our Services and develop new features.
<p className='sub-heading'>Legal Compliance<br></br></p>
We may use your information to comply with legal obligations, resolve disputes, and enforce our terms and policies.

<p className="heading blue">Sharing Your Information</p>
We may share your information with the following
<p className='sub-heading'>Third-Party Vendors<br></br></p>
We may share information with third-party vendors who provide services on our behalf, such as payment processing, analytics, and marketing.
<p className='sub-heading'>Legal Authorities<br></br></p>
We may disclose information to comply with legal requirements, respond to requests from law enforcement, or protect our rights and safety.

<p className="heading blue">Your Choices</p>
You have control over your personal information
<p className='sub-heading'>Account Information<br></br></p>
You can update or delete your account information at any time through our platform settings.
<p className='sub-heading'>Communication<br></br></p>
You can manage your communication preferences and opt-out of promotional emails.
<p className='sub-heading'>Location<br></br></p>
You can control location access through your device settings or app permissions.

<p className="heading blue">Data Security</p>
We implement security measures to protect your data. However, no method of transmission or storage is entirely secure, so we cannot guarantee absolute security.
<p className='sub-heading'>Breaches<br></br></p>
If PetNotch experiences a data security breach, we will notify users as soon as possible and take steps to mitigate the damage caused by the breach.

<p className="heading blue">Data Retention</p>
We retain user account information for as long as the user's account is active and also an year after the user chooses to delete their account unless otherwise advised to us by user. Usage data is retained for a period of six months.

<p className="heading blue">Children's Privacy</p>
Our Services are not intended for children under 13. If we learn that we have collected personal information from a child under 13 without parental consent, we will take steps to delete the data.

<p className="heading blue">Changes to this Privacy Policy</p>We may update this Privacy Policy to reflect changes in our practices or for legal reasons. We will notify you of significant changes through our platform or other means.

<p className="heading blue">Dispute Resolution</p>
Users can contact PetNotch's customer support team through the app or send us an email at support@petnotch.com to resolve any privacy-related issues. Users may also file a complaint with the relevant data protection authority.

<p className="heading blue">Contact Us</p>
<p className='sub-heading'>Account Termination<br></br></p>
If you have questions or concerns about this Privacy Policy or your personal information, please contact us at support@petnotch.com.
<br></br>
<br></br>
<br></br>
Thank you for trusting PetNotch with your information.
      </div>
    </div>
  );
};

export default PrivacyPolicy;
