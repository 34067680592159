import React from "react";
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
    
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import NavbarMinimal from "./components/NavbarMinimal";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import MobileApp from "./pages/MobileApp";
import NotFound from "./pages/PageNotFound";
import Services from "./pages/Services";
import TermsOfUse from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DeleteAccount from "./pages/DeleteAccount";
import Blog from "./pages/Blog";
import ReactGA from "react-ga4";

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(TRACKING_ID);
}

function App() {
  ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
  return (
    <Router>
        <Helmet>
            {/* Dynamically set title and description based on route or component */}
            <title>PetNotch: The All-in-One Community Driven Pet Care & Management Platform</title>
            <meta name="description" content="PetNotch connects pet lovers, offers essential services, and fosters a supportive community. Find playdates, pet sitters, mating partners, lost pets and more within your community! Download the app today!" />
            <meta name="keywords" content="home, services, about, mobile app contact" />
            <link rel="canonical" href="https://petnotch.com/" />
            {/* Add Open Graph and structured data markup as needed */}
        </Helmet>
        <Routes>
            <Route
                path="/"
                exact
                element={
                <>
                    <Navbar />
                    <div id="home"><Home /></div>
                    <div id="services"><Services /></div>
                    <div id="about"><About /></div>
                    <div id="mobileapp"><MobileApp /></div>
                    <div id="contact"><Contact /></div>
                </>
                }
            />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/legal/terms-of-use" element={<TermsOfUse />} />
            <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/user/actions/delete-account" element={<DeleteAccount />} />
            <Route path="/mobileapp/download" element={<MobileApp/>} />
            <Route path="/blogs/mobileapp/download" element={<MobileApp/>} />
            <Route path="/blogs" element={
                <>
                    <NavbarMinimal />
                    <div id="blog"><Blog /></div>
                </>
            } />
            <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
    </Router>
  );
}

export default App;
