import React, { useState } from 'react';
import { Link } from 'react-scroll';

import logo from "../assets/logo.png";


const NavbarMinimal = () =>  {

  const handleMobileAppRedirect = (e) => {
    e.preventDefault();
    window.location.href = "blogs/mobileapp/download";
  }

  return (
    <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="relative flex items-center justify-between">
        <a
          href="/"
          aria-label="Company"
          title="Company"
          className="inline-flex items-center"
        >
          <img src={logo} style={{ height: 50, width: 50 }} alt="PetNotch" />
        </a>
        <ul className="flex items-center hidden space-x-8 lg:flex">
          <Link
                offset={-50}
                style={{ textDecoration: "none" }}
                spy={true}
                smooth={true}
                duration={1000}
                onClick={handleMobileAppRedirect}
              >
                <a
                  href="/"
                  className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-secondary hover:bg-primary focus:shadow-outline focus:outline-none"
                  aria-label="Download Mobile App"
                  title="Download App"
                >
                  Download App
                </a>
              </Link>
        </ul>
      </div>
    </div>
  );
};

export default NavbarMinimal;
