
import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

import screenshots from "../assets/screenshots.png";
import yellow from "../assets/yellow.png";
import iosStore from "../assets/app-store.png";
import androidStore from "../assets/google-play.png";


const MobileApp = () => {
  const location = useLocation();
  useEffect(() => {
    const url = location.pathname;
    let action = 'Mobile App Button Click';
    // Check if the current URL path ends with /mobileapp/download
    if (location.pathname.endsWith('blogs/mobileapp/download')) {
      // Update action to reflect the direct URL visit
      action = 'Mobile App Link Click Through Blogs';
    }
    else if (location.pathname.endsWith('/mobileapp/download')) {
      // Update action to reflect the direct URL visit
      action = 'Mobile App Direct Link Click';
    }
    
    ReactGA.event({
      category: 'Mobile App',
      action: action,
      label: url,
      nonInteraction: false
    });
  }, [location]);
  return (
    <div
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      id="mobile"
    >
      <div className="grid gap-10 lg:grid-cols-2">
        <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
          <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-secondary sm:text-4xl sm:leading-none">
            Join The Fastest Growing Pet Care & Management Platform
            <br className="hidden md:block" />
          </h2>
          <p className="pr-5 mb-5 text-base text-gray md:text-md">
          Unleash the power of pet management with <p className="font-bold text-secondary inline-block">PetNotch!</p>
          <br></br>
          <br></br>
          Download the mobile app now from iOS and Android respective app stores to join our mission{" "}
            {"\n"}
          </p>

          <div className="flex items-center space-x-3">
            <a
              href="https://apps.apple.com/us/app/petnotch/id6468765382/"
              className="w-48 transition duration-300 hover:shadow-lg"
              target="_blank"
            >
              <img
                src={iosStore}
                className="object-cover object-top w-full h-auto mx-auto"
                alt="Apple App Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.petnotch.android"
              className="w-48 transition duration-300 hover:shadow-lg"
              target="_blank"
            >
              <img
                src={androidStore}
                className="object-cover object-top w-full h-auto mx-auto"
                alt="Android Play Store"
              />
            </a>
          </div>
        </div>
        <div>
          <img src={yellow} style={{ zIndex: 0, marginBottom: "-80%" }} />

          <img
            className="object-cover"
            src={screenshots}
            alt=""
            style={{ zIndex: 99 }}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileApp;
