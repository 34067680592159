import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Carousel from 'react-multi-carousel';
import Markdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import rehypeKatex from 'rehype-katex'
import remarkGfm from 'remark-gfm'
import Moment from 'react-moment';

import 'katex/dist/katex.min.css'
import 'react-multi-carousel/lib/styles.css';


import blogData from "../assets/blogs.json"

const Blog = () => {
  const location = useLocation();
  const [blogs, setBlogs] = useState(blogData);

  useEffect(() => {
    const url = location.pathname;
    let action = 'Blog Page Visit';
    
    ReactGA.event({
      category: 'Blog',
      action: action,
    });

    // Fetch blogs from your API here and update the blogs state
  }, [location]);

  return (
    <div className="bg-primary" id="blogs">
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: 1,
            partialVisibilityGutter: 40
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: 1,
            partialVisibilityGutter: 30
          }
        }}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {blogs.map((blog, index) => (
          <div key={index} className="bg-white p-10 mx-40 my-20 text-gray font-sans">
            <h3 className="max-w-xlg mb-20 font-sans font-bold leading-none tracking-tight text-secondary text-4xl lg:text-3xl mx-auto text-center">
              {blog.title}
            </h3>
            <div>
            <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeKatex]}>{blog.content}</Markdown>
            </div>
            <br></br>
            <div className="flex items-center mt-4 space-x-8 sm:my-10">
              <img src={blog.authorImage} className="w-20 h-20 rounded-full" alt="Author" />
              <div>
                <p>{blog.author}</p>
                <Moment format="D MMM YYYY">{blog.date}</Moment>
              </div>
            </div>
            <div className="relative w-full border-t border-black text-center">
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Blog;