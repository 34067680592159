import yellow from "../assets/yellow.png";

const About = () => {
  return (
    <div
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      id="about"
    >
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-black sm:text-6xl md:mx-auto">
          Our Vision
        </h2>
        <p className="text-base text-gray md:text-lg">
        PetNotch envisions a world where all pets, regardless of their shape or size, can live healthy and fulfilling lives. We strive to create a safe and nourishing environment for all pets, where their well-being is our top priority. We aim to be the pocket carer for pet carers, providing a seamless way to manage their pets. Our app is driven by sophisticated AI technology, offering a 360° pet management platform.
        <br></br><br></br>
        We aspire to be the go-to app for all pet parents, a platform that connects them with other pet parents and provides essential resources to help them care for their furry friends. Our vision is to empower pet parents to make informed decisions about their pet's health and well-being. We aim to create a community where pet parents can connect, share their experiences, and learn from each other.
        <br></br><br></br>
        We are constantly innovating to improve our platform and introduce new features that enhance the pet parenting experience. We believe that every pet deserves the best care possible, and we are committed to delivering it. Our ultimate goal is to create a world where pets are happy, healthy, and loved, and where every pet parent has access to the tools and resources they need to provide the best possible care for their furry friends.
        </p>
      </div>
      
      <div className="grid max-w-screen-lg gap-10 lg:grid-cols-2 sm:mx-auto">
        {/* <img
          src={yellow}
          style={{ zIndex: 0, marginBottom: "-80%", marginRight: "-80%" }}
        /> */}
        {/* 
        <div className="grid grid-cols-2 gap-5">
          <img
            style={{ zIndex: 99 }}
            className="object-cover w-full h-56 col-span-2 rounded shadow-lg"
            src="https://images.pexels.com/photos/6235233/pexels-photo-6235233.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
          />
          <img
            style={{ zIndex: 99 }}
            className="object-cover w-full h-48 rounded shadow-lg"
            src="https://images.pexels.com/photos/7516216/pexels-photo-7516216.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
          />
          <img
            style={{ zIndex: 99 }}
            className="object-cover w-full h-48 rounded shadow-lg"
            src="https://images.pexels.com/photos/7726294/pexels-photo-7726294.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
          />
        </div>
        <div className="flex flex-col justify-center">
          <div className="pb-4 mb-4 border-b">
            <h6 className="mb-2 font-semibold leading-5">
              Sit error voluptatem accusantium
            </h6>
            <p className="text-sm text-gray">
              Sportacus andrew weatherall goose Refined gentlemen super mario
              des lynam alpha trion zap rowsdower, omar sharif old.
            </p>
          </div>
          <div className="pb-4 mb-4 border-b">
            <h6 className="mb-2 font-semibold leading-5">
              Cheese on toast airedale the big cheese
            </h6>
            <p className="text-sm text-gray">
              Chase ball of string eat plants, meow, and throw up because I ate
              plants going to catch the red dot today going to catch the red dot
              today. I could pee on this if I had the energy.
            </p>
          </div>
          <div>
            <h6 className="mb-2 font-semibold leading-5">
              A flower in my garden, a mystery
            </h6>
            <p className="text-sm text-gray">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae doloremque laudantium.
            </p>
          </div>
        </div>
        */}
      </div>
    </div>
  );
};

export default About;
