import { AccessTime } from "@mui/icons-material";

const Services = () => {
  return (
    <>
      <div
        className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
        id="services"
      >
        <div className="max-w-xl mb-15 md:mx-auto sm:text-center lg:max-w-2xl md:mb-15">
          <h2 className="text-base text-gray text-4xl mb-4 font-medium">
            How can we help?
          </h2>

          <h2 className="max-w-lgfont-sans text-1xl font-medium leading-none tracking-tight text-secondary sm:text-5xl md:mx-auto mb-20">
            Wrong question.. Ask how can we not help?
          </h2>
          
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className="max-w-xl pr-16 mx-auto mb-10">
            <h5 className="mb-6 text-5xl font-bold leading-none">
              Social Media
            </h5>
            <p className="mb-6 text-gray">
              Our ultimate social media plaform for pets and their carers!. 
              <br></br><br></br>
              Whether your furry friend is a celebrity or simply the love of your life, PetNotch is the perfect place to 
              connect with other pet lovers from around the world. 
              With PetNotch, pet carers can learn from each other's experiences, ask for recommendations, 
              share photos and videos of their pets, 
              and even showcase their pets as celebrities. 
              <br></br><br></br>
              At the same time, pets can gain fame, love and adoration from the community, 
              and receive the attention they deserve. 
              <br></br><br></br>
              Join PetNotch today and start sharing your love for pets with the world!
            </p>
          </div>
          <div className="grid gap-5 row-gap-5 sm:grid-cols-2">
            <div className="max-w-md">
                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-primaryBg text-primary">
                  <AccessTime />
                </div>

                <h6 className="mb-2 font-semibold leading-5">
                  Show off your love
                </h6>
                <p className="text-sm text-gray">
                  Unleash your pet's inner pawparazzi and share their cuteness with the world on PetNotch!
                </p>
            </div>

            <div className="max-w-md">
                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-primaryBg text-primary">
                  <AccessTime />
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  Who's the best vet in the town?
                </h6>
                <p className="text-sm text-gray">
                  Ask for recommendations for anything and everything pet-related on PetNotch - the pawfect place to find your fur-ever answers!
                </p>
            </div>

            <div className="max-w-md">
                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-primaryBg text-primary">
                  <AccessTime />
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  Join the Pawty
                </h6>
                <p className="text-sm text-gray">
                  Paw-lease your pet's social needs and connect with like-minded pet lovers on PetNotch's community groups- where every pet has a pack!
                </p>
            </div>

            <div className="max-w-md">
                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-primaryBg text-primary">
                  <AccessTime />
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  Recharge with PetNotch
                </h6>
                <p className="text-sm text-gray">
                  Paws for a moment and recharge with PetNotch - the fur-bulous place to watch your favorite pet videos, pictures, and unwind after a ruff day!
                </p>
            </div>

          </div>
        </div>
        <div className="flex flex-col lg:flex-row-reverse mt-40">
          <div className="max-w-xl pl-16 mx-auto mb-10">
            <h5 className="mb-6 text-5xl font-bold leading-none">
              Events
            </h5>
            <p className="mb-6 text-gray">
            PetNotch's Events feature is a dedicated space where pet carers can post and discover pet-specific events in their local community. 
            Whether you're looking to connect with other pet carers, find new resources for your furry friend, or offer support to pets in need, 
            our Events feature makes it easy to get the word out to the wider community of pet lovers. With our user-friendly event templates, 
            you can easily create and share your own events or discover and attend events posted by other members of the PetNotch community.
            <br></br><br></br>
            Additionally, the one-to-one chat option allows carers to connect directly with other carers to learn more about an event or coordinate plans
            </p>
          </div>
          <div className="grid gap-5 row-gap-5 sm:grid-cols-2">
              <div className="max-w-md">
                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-primaryBg text-primary">
                  <AccessTime />
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  Play Date
                </h6>
                <p className="text-sm text-gray">
                Looking for a paw-some way to socialize your pet and connect with other pet carers? Check out the Play Date event on PetNotch! 
                Arrange a date for your furry friend to meet and play with other pets in the community. 
                It's a great way to keep your pet active, happy, and healthy. Plus, you can make new friends too! 
                Get ready for some tail-wagging fun!
                  </p>
              </div>

              <div className="max-w-md">
                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-primaryBg text-primary">
                  <AccessTime />
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  Look After
                </h6>
                <p className="text-sm text-gray">
                  Leaving your furry friend behind for a few hours or days? 
                  No worries, the Look After event has got you covered. 
                  Find trustworthy carers to take care of your pet while you're away. 
                  Choose the right carer based on their profile, discussion, and reviews. 
                  Good carers get good reviews, so rest assured that your pet is in good hands. 
                  It's the purrfect solution for pet carers on the go!
                  </p>
              </div>

              <div className="max-w-md">
                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-primaryBg text-primary">
                  <AccessTime />
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  Lost & Found
                </h6>
                <p className="text-sm text-gray">
                  The Lost & Found event on PetNotch is a helpful tool for pet carers to post about their missing pets 
                  and connect with others in the community who may have found their furry friends. 
                  Even though you might be tempted to keep a found pet because it's so cute, 
                  it's best for the pet to be reunited with its carer. 
                  So post away, and let's help lost pets find their way back home!
                </p>
              </div>

              <div className="max-w-md">
                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-primaryBg text-primary">
                  <AccessTime />
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  Mating Partner
                </h6>
                <p className="text-sm text-gray">
                Who doesn't like the arrival of new baby in family? 
                PetNotch's Mating Partner event is the perfect way to find a compatible partner for your pet. 
                Browse through other carers' profiles, engage in discussions, and connect with potential partners. 
                It's a fun and safe way to bring new life into your home. Happy matching!
                </p>
              </div>

              <div className="max-w-md">
                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-primaryBg text-primary">
                  <AccessTime />
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  Buy or Adopt & Sell or Donate
                </h6>
                <p className="text-sm text-gray">
                Looking for a new furry family member or trying to find a loving home for your pet? 
                Look no further than PetNotch's buy, sell, adopt, or donate pet event! 
                With our easy-to-use template, pet carers can share their pet preferences and connect 
                with others in their area. Plus, carers can choose to pay for the pet or receive money 
                in return, making the process smooth and hassle-free. Find your perfect match or give 
                your pet a loving new home today on PetNotch!
                </p>
              </div>

              <div className="max-w-md">
                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-primaryBg text-primary">
                  <AccessTime />
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  Training
                </h6>
                <p className="text-sm text-gray">
                If you're looking to transform your beloved companion into a well-behaved and obedient furry family member, 
                go nowhere else and simply create a Training Event to find your pet a qalified trainer. If not more, 
                community can help you find the right trainer. 
                With our Training Event, you can ensure that your furry friend is on their way 
                to becoming the best version of themselves.  After all, everyone loves a well-behaved child, furry or not!
                </p>
              </div>
              
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
