import yellow from "../assets/yellow.png";

const Home = () => {
  return (
    <div
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      id="home"
    >
      <div className="grid gap-10 lg:grid-cols-2">
        <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
          <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-secondary sm:text-6xl sm:leading-none">
            Take good care
            <br className="hidden md:block" />
            <span className="inline-block text-primary text-6xl ">
              of <span className="text-secondary" style={{textDecoration: 'line-through'}}><span className="text-primary">your</span></span>{' '} <span className="text-secondary">our</span> pets.
            </span>
          </h2>
          <p className="pr-5 mb-5 text-base text-gray md:text-md">
            World's most sophisticated 360&deg; pet management platform, designed to make it easy for pet parents to manage all aspects of their pet's life.{" "}
            {"\n"}
          </p>
          <a
            href="/"
            className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-secondary hover:bg-primary focus:shadow-outline focus:outline-none"
            aria-label="About Us"
            title="About Us"
          >
            About Us
          </a>
        </div>

        <div>
          <img src={yellow} style={{ zIndex: 0, marginBottom: "-80%" }} alt="Yellow" />
          <img
            style={{ zIndex: 99 }}
            // className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
            src="https://www.pngall.com/wp-content/uploads/10/Pet-PNG-Clipart-Background.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
