import logo_variant from "../assets/logo_variant.png";
import {
  Facebook,
  Instagram,
//  LocalPhoneOutlined,
  LinkedIn,
} from "@mui/icons-material";

const Footer = () => {
  const tabs = ["Home", "About Us", "Services", "Mobile App", "Contact Us", "Terms of Use", "Privacy Policy"];
  const tabs_map = {
    "Terms of Use": "legal/terms-of-use",
    "Privacy Policy": "legal/privacy-policy",
  }
  return (
    <div className="relative mt-6 bg-secondary">
      <svg
        className="absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 text-secondary"
        preserveAspectRatio="none"
        viewBox="0 0 1440 54"
      >
        <path
          fill="currentColor"
          d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"
        />
      </svg>
      <div className="px-4 pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="grid row-gap-8 mb-10 lg:grid-cols-3">
          <div className="md:max-w-md lg:col-span-1 flex flex-row justify-center">
            <img src={logo_variant} style={{ width: 160, height: 160 }} alt="PetNotch" />
            <div className="lg:max-w-sm ml-5">
              <h2 className=" text-xl font-bold tracking-wide text-white">
                PetNotch
              </h2>
              <p className="text-sm text-white">
              PetNotch is committed to empowering pet carers to make their own informed decisions. 
              PetNotch only facilitates connections and information sharing, users take full responsibility for their interactions. 
              We provide a secure and convenient space for pet carers to connect and trust them to make informed choices.
              </p>
            </div>
          </div>
          <div className="lg:col-span-1" />
          <div className="lg:col-span-1 grid grid-cols-2 grid-flow-col">
            {Object.entries(tabs_map).map(([item, url], index) => (
              <ul>
                  <li key={index}>
                    <a
                      href={url}  // Use the URL from tabs_map
                      className="transition-colors duration-300 text-white hover:text-primary"
                    >
                      {item}
                    </a>
                  </li>
              </ul>
            ))}
          </div>
        </div>
        <div className="flex flex-col justify-between pt-5 pb-10 border-t border-white sm:flex-row">
          <p className="text-sm text-white">
            © Copyright 2023 PetNotch Pty Ltd. All rights reserved.
          </p>
          <div className="flex items-center mt-4 space-x-4 sm:mt-0">
            
            <a
              href="https://instagram.com/petnotch"
              className="transition-colors duration-300 text-white hover:text-primary"
            >
              <Instagram />
            </a>
            <a
              href="https://facebook.com/petnotch"
              className="transition-colors duration-300 text-white hover:text-primary"
            >
              <Facebook />
            </a>

            <a
              href="https://linkedin.com/company/petnotch"
              className="transition-colors duration-300 text-white hover:text-primary"
            >
              <LinkedIn />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
