import React from 'react';

function NotFound() {
  return (
    <div style={styles.container}>
      <div style={styles.errorCode}>404</div>
      <div style={styles.errorMessage}>Page Not Found</div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
  },
  errorCode: {
    fontSize: '6rem',
    fontWeight: 'bold',
    color: '#333',
  },
  errorMessage: {
    fontSize: '1.5rem',
    color: '#555',
  },
};

export default NotFound;
