import React, { useState } from 'react';
import {isMobile, isAndroid, isIOS} from 'react-device-detect';
import { Link } from 'react-scroll';
import { toast } from 'react-toastify';
import logo from "../assets/logo.png";


function Navbar(
  showHome = true,
  showServices = true,
  showAbout = true,
  showContact = true,
  showMobileApp = true,
  showBlogs = true
) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navItems = []
  if (showHome) {
    navItems.push({
      title: "Home",
      url: "home",
    });
  }
  if (showServices) {
    navItems.push({
      title: "Services",
      url: "services",
    });
  }
  if (showAbout) {
    navItems.push({
      title: "About Us",
      url: "about",
    });
  }
  if (showContact) {
    navItems.push({
      title: "Contact Us",
      url: "contact",
    });
  };

  const handleBlogRedirect = (e) => {
    e.preventDefault();
    window.location.href = "/blogs";
  };


  const handleDownloadClick = (e) => {
    // Check if the user is on a mobile device
      e.preventDefault();
      const CustomToastWithLink = () => (
        //Underline the mobile app in the text
        <div>
          <p>Take me straight to the <span style={{textDecoration: 'underline'}}>mobile app</span></p>
        </div>
      );
      // Check if the user is on a mobile device
      if (isMobile) {
        // Show a prompt to the user
        toast(CustomToastWithLink, {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClick: () => {
            // Determine the device type using the user agent string
    
            // Android
            if (isAndroid) {
              window.location.href = 'https://play.google.com/store/apps/details?id=com.petnotch.android';
            }
            // iOS
            else if (isIOS) {
              window.location.href = 'https://apps.apple.com/us/app/petnotch/id6468765382/';
            }
          }
        });
      }
    }

  return (
    <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="relative flex items-center justify-between">
        <a
          href="/"
          aria-label="Company"
          title="Company"
          className="inline-flex items-center"
        >
          <img src={logo} style={{ height: 50, width: 50 }} alt="PetNotch" />
        </a>
        <ul className="flex items-center hidden space-x-8 lg:flex">
          {navItems.map((item, index) => (
            <Link
              offset={-50}
              style={{ textDecoration: "none" }}
              spy={true}
              smooth={true}
              duration={1000}
              to={item.url}
            >
              <a
                href="/"
                aria-label={item.url}
                title={item.title}
                className="font-medium tracking-wide text-gray transition-colors duration-200 hover:text-secondary"
              >
                {item.title}
              </a>
            </Link>
          ))}
            { showBlogs && (
              <Link
                offset={-50}
                style={{ textDecoration: "none" }}
                spy={true}
                smooth={true}
                duration={1000}
                to={"blogs"}
                onClick={handleBlogRedirect}
              >
                <a
                  href="/bogs"
                  className="font-medium tracking-wide text-gray transition-colors duration-200 hover:text-secondary"
                  aria-label="Blogs"
                  title="Blogs"
                >
                  Blogs
                </a>
              </Link>
            )}

          {showMobileApp && (

            <Link
              offset={-50}
              style={{ textDecoration: "none" }}
              spy={true}
              smooth={true}
              duration={1000}
              to={"mobile"}
              onClick={handleDownloadClick}
            >
              <a
                href="/"
                className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-secondary hover:bg-primary focus:shadow-outline focus:outline-none"
                aria-label="Download Mobile App"
                title="Download App"
              >
                Download App
              </a>
            </Link>
          )}
        </ul>
        <div className="lg:hidden">
          <button
            aria-label="Open Menu"
            title="Open Menu"
            className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
            onClick={() => setIsMenuOpen(true)}
          >
            <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
              />
              <path
                fill="currentColor"
                d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
              />
              <path
                fill="currentColor"
                d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
              />
            </svg>
          </button>
          {isMenuOpen && (
            <div className="absolute top-0 left-0 w-full">
              <div className="p-5 bg-white rounded drop-shadow-lg">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <a
                      href="/"
                      aria-label="Company"
                      title="Company"
                      className="inline-flex items-center"
                    >
                      <img src={logo} style={{ height: 30, width: 30 }} alt="PetNotch" />
                      <span className="ml-2 text-xl font-bold tracking-wide text-gray-800">
                        PetNotch
                      </span>
                    </a>
                  </div>
                  <div>
                    <button
                      aria-label="Close Menu"
                      title="Close Menu"
                      className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <nav>
                  <ul className="flex flex-col space-y-4 ">
                    {navItems.map((item, index) => (
                      <Link
                        offset={-50}
                        style={{ textDecoration: "none" }}
                        spy={true}
                        smooth={true}
                        duration={1000}
                        to={item.url}
                      >
                        <a
                          href="/"
                          aria-label={item.url}
                          title={item.title}
                          className="font-medium tracking-wide text-gray transition-colors duration-200 hover:text-secondary"
                        >
                          {item.title}
                        </a>
                      </Link>
                    ))}

                    <Link
                      offset={-50}
                      style={{ textDecoration: "none" }}
                      spy={true}
                      smooth={true}
                      duration={1000}
                      to={"mobile"}
                      onClick={handleDownloadClick}
                    >
                      <a
                        href="/"
                        className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-secondary hover:bg-primary focus:shadow-outline focus:outline-none mt-5"
                        aria-label="Mobile App"
                        title="Mobile App"
                      >
                        Download Mobile App
                      </a>
                    </Link>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
